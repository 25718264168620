/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+NP+Hmong:wght@500&display=swap');
.hero {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.hero h1 {
  margin-top: 3rem;
  /* font-family: 'Roboto Slab', serif; */
  font-family: 'Noto Serif NP Hmong', serif;
  font-size: 2rem;
  color: #999;
}

.nav_container {
  margin: 0;
  display: flex;
  align-items: center;
  height: 4rem;
  width: calc(100vw - 6rem);
}

.nav_container img {
  height: 2.5rem;
  margin-inline-end: 2rem;
}

.nav_container a,
.nav_container span {
  padding-inline: 0.5rem;
  color: lightgray;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #001529;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 100;
}

.dropdownContent a {
  padding: 4px 16px;
  text-decoration: none;
  display: block;
  line-height: 2rem;
}

.dropdown:hover .dropdownContent {
  display: block;
}

/* .dropdownContent a:hover {
  color: yellowgreen;
} */

.nav_container a.active,
.nav_container span.active {
  background: linear-gradient(180deg, hsla(151, 86%, 37%, 0.1) 70%, hsla(151, 50%, 37%, 0.3));
  color: yellowgreen;
}

.nav_container .right_items {
  margin-left: auto;
}
